import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/team",
    name: "Team",
    component: () =>
      import(/* webpackChunkName: "team" */ "../components/Team.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Products.vue"),
  },
  {
    path: "/apply",
    name: "Apply",
    component: () =>
      import(/* webpackChunkName: "apply" */ "../views/auth/Register.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass:
    "bg-blue-600 rounded md:bg-transparent md:text-blue-600 text-white hover:text-gray-700",
});

export default router;

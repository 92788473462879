<template>
  <footer class="text-gray-600 body-font">
    <div
      class="container flex flex-col items-center px-5 py-8 mx-auto sm:flex-row"
    >
      <a
        class="flex items-center justify-center font-medium text-gray-900  dark:text-white title-font md:justify-start"
      >
        <span class="ml-3 text-xl">Apar's Tech Team</span>
      </a>
      <p
        class="mt-4 text-sm text-gray-500  dark:text-white sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0"
      >
        © 2022 Apar's Tech Team —
        <a
          href="https://twitter.com/knyttneve"
          class="ml-1 text-gray-600"
          rel="noopener noreferrer"
          target="_blank"
          >@aparsclassroom</a
        >
      </p>
      <span
        class="inline-flex justify-center mt-4  sm:ml-auto sm:mt-0 sm:justify-start"
      >
        <a class="text-gray-500">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
            ></path>
          </svg>
        </a>
      </span>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
<template>
  <nav
    class="border-gray-200 border-b glass sticky top-0 px-2 sm:px-4 py-2.5 z-10"
  >
    <div class="container flex flex-wrap items-center justify-between mx-auto">
      <a href="#" class="flex">
        <span
          class="
            self-center
            text-lg
            font-semibold
            dark:text-white
            whitespace-nowrap
          "
          >Tech Team</span
        >
      </a>
      <div class="flex md:order-2">
        <div class="hidden space-x-2 sm:block">
          <!-- <router-link
            type="button"
            class="menu-btn"
            v-if="loggedIn"
            @click="loggedIn = !loggedIn"
          >
            Profile
          </router-link> -->

          <button
            type="button"
            class="menu-btn"
            @click="
              () => {
                mobile = !mobile;
                $router.push('/login');
              }
            "
          >
            Login
          </button>
          <button
            type="button"
            class="menu-btn"
            @click="
              () => {
                mobile = !mobile;
                $router.push('/apply');
              }
            "
          >
            Apply
          </button>
        </div>
        <button
          data-collapse-toggle="mobile-menu-4"
          type="button"
          class="
            inline-flex
            items-center
            p-2
            text-sm text-gray-500
            rounded-lg
            md:hidden
            hover:bg-gray-100focus:outline-none
            focus:ring-2 focus:ring-gray-200
            dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600
          "
          aria-controls="mobile-menu-4"
          aria-expanded="false"
          @click="mobile = !mobile"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            class="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div
        class="items-center justify-between w-full md:flex md:w-auto md:order-1"
        :class="{ hidden: mobile }"
        id="mobile-menu-4"
      >
        <ul
          class="
            flex flex-col
            mt-4
            md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium
          "
        >
          <li>
            <router-link
              @click="mobile = !mobile"
              to="/"
              class="menu-item"
              aria-current="page"
              >Home</router-link
            >
          </li>
          <li>
            <router-link @click="mobile = !mobile" to="/team" class="menu-item"
              >Team</router-link
            >
          </li>
          <li>
            <router-link
              @click="mobile = !mobile"
              to="/products"
              class="menu-item"
              >Products</router-link
            >
          </li>
          <li>
            <router-link @click="mobile = !mobile" to="/about" class="menu-item"
              >About</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      loggedIn: false,
      mobile: true,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
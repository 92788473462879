<template>
  <hero id="home"></hero>
</template>

<script>
import HeroVue from "../components/Hero.vue";
export default {
  name: "Home",
  components: {
    Hero: HeroVue,
  },
};
</script>

<template>
  <section class="grid h-full py-10 mt-10 space-y-6 place-items-center">
    <div
      class="relative w-full max-w-lg py-3 mt-32 text-center text-transparent bg-black  bg-clip-text"
    >
      <div
        class="absolute top-0 hidden bg-green-500 rounded-full opacity-50  w-72 h-72 -left-4 mix-blend-multiply filter blur-2xl sm:block"
      ></div>
      <div
        class="absolute top-0 hidden bg-yellow-500 rounded-full opacity-50  w-72 h-72 -right-4 mix-blend-multiply filter blur-2xl sm:block"
      ></div>
      <div
        class="absolute hidden bg-blue-500 rounded-full opacity-50  w-72 h-72 -bottom-8 left-20 mix-blend-multiply filter blur-2xl sm:block"
      ></div>
      <h1 class="text-4xl font-extrabold dark:text-white sm:text-6xl">
        Apar's Tech Team
      </h1>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
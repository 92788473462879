<template>
  <div
    class="
      antialiased
      dark:bg-gradient-to-r dark:from-gray-700 dark:via-gray-900 dark:to-black
      bg-gradient-to-r
      from-blue-50
      via-purple-50
      to-blue-100
      bangla
    "
  >
    <Header />
    <div class="hscreen"><router-view /></div>
    <Footer />
  </div>
</template>

<script>
import HeaderVue from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header: HeaderVue,
    Footer,
  },
  setup() {
    return {};
  },
  created() {
    let currentPath = localStorage.getItem("path");
    if (currentPath) {
      localStorage.removeItem("path");
      this.$router.replace(currentPath);
    }
  },
};
</script>

<style lang="scss" scoped>
.hscreen {
  min-height: 85vh;
}
</style>